<template>
    <app-layout v-loading="pageLoading" size="none" :needHeader="false">
    <PlanningDetail :planId="$route.query.planId" :iscompoent="true"/>
    <div slot="footer" style="text-align:center;">
      <el-button @click="$router.back()">取消</el-button>
      <el-button type="success" @click="dialog=true;dtitle='审批通过'">通过</el-button>
      <el-button @click="dialog=true;dtitle='审批不通过'" type="danger">不通过</el-button>
    </div>
    <el-dialog :title="dtitle" :visible.sync="dialog" center width="35%" class="messageDigBox">
        <span class="label">{{dtitle=='审批通过'?'通过说明':'不通过原因'}}</span>
        <el-input
        type="textarea"
        :rows="3"
        :maxlength="50"
        placeholder="1-50个字符"
        v-model="remark">
        </el-input>
        <div slot="footer">
            <el-button @click="dialog = false">取 消</el-button>
            <el-button :loading="btnloading" type="primary" @click="apporoveSubmit">确 定</el-button>
        </div>
    </el-dialog>
    </app-layout>
</template>

<script>
import { approvesubmit } from '../../api/staffSystem';
import PlanningDetail from '../manpowerplanning/mpResult/resultDetail';
export default {
    components: {
        PlanningDetail
    },
    data() {
        return {
            title: '人才规划',
            pageLoading: false,
            dialog: false,
            btnloading: false,
            remark: '',
            dtitle: '审批通过'
        };
    },
    methods: {
        async apporoveSubmit() {
          this.btnloading = true;
          if (!this.remark && this.dtitle == '审批不通过') {
            this.$message.warning('请输入不通过原因');
          } else {
            const res = await approvesubmit({approvalId: this.$route.query.approvalId, status: this.dtitle === '审批不通过' ? '3' : '2', remark: this.remark});

            if (res.code == 0) {
              this.dialog = false;
              this.btnloading = false;
              this.$message.success('审批已提交');
              this.$router.push({path: '/staff/talent_planning'});
            }
          }
          this.btnloading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.messageDigBox {
  .label {
    margin-left: 5%;
    transform: translateY(6px);
  }
}
</style>
